import { BrowserRouter, Routes, Route } from "react-router-dom";

import {
  AboutTeleRad,
  Exaworks,
  Footer,
  Header,
  WorkUs,
  Home,
  Consult,
  Pacs,
  Partner,
  Contact,
  Faq,
  IpaBanner,
  SystemIntegration,
  Raas,
} from "./containers";

import { languages } from "./assets";
import ScrollToTop from "./utilities/scrollTop";
import "./app.css";
import useLanguageSelect from "./hooks/useLanguageSelect";
import { MediaQueryProvider } from "./utilities/mediaQuery";

function App() {
  const { languageSelect, languageSelected, activeLanguage, activeBanner } =
    useLanguageSelect();

  const {
    navigation,
    opener,
    aboutUs,
    consult,
    bePartner,
    features,
    buttons,
    pacs,
    raas,
    systemIntegration,
    faq,
    exaworks,
    contact,
    footerContent,
  } = activeLanguage(languageSelected);

  return (
    <MediaQueryProvider width={1024}>
      <BrowserRouter>
        <ScrollToTop>
          <div className="App">
            <Header
              linkList={navigation}
              languages={languages}
              languageSelect={languageSelect}
            />
            <Routes>
              <Route
                path="/"
                element={
                  <Home
                    slideContent={opener}
                    aboutContent={aboutUs}
                    consultContent={consult}
                    bePartnerContent={bePartner}
                    featuresContent={features}
                    buttons={buttons}
                  />
                }
              />
              <Route
                path="about"
                element={<AboutTeleRad aboutContent={aboutUs} />}
              />
              <Route path="raas" element={<Raas content={raas} />} />
              <Route
                path="exaworks"
                element={<Exaworks content={exaworks} />}
              />
              <Route path="pacs" element={<Pacs content={pacs} />} />
              <Route
                path="integration"
                element={<SystemIntegration content={systemIntegration} />}
              />
              <Route path="consult" element={<Consult content={consult} />} />
              <Route
                path="partner"
                element={
                  <Partner
                    partnerContent={bePartner}
                    featuresContent={features}
                  />
                }
              />
              <Route path="faq" element={<Faq faqContent={faq} />} />
              <Route
                path="contact"
                element={<Contact contactContent={contact} />}
              />
              {/* <Route path="news" element={<News />} /> */}
            </Routes>
            <WorkUs contactContent={contact} />
            <IpaBanner imgArray={activeBanner(languageSelected)} />
            <Footer footerContent={footerContent} />
          </div>
        </ScrollToTop>
      </BrowserRouter>
    </MediaQueryProvider>
  );
}

export default App;
