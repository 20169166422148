import {
  Article,
  Banner,
  Image,
  PromoBanner,
  AccordionItem,
} from "../../components";
import "./aboutTelerad.css";
import { scanImage1Right, deskConsult2 } from "../../assets";

const AboutTelerad = ({ aboutContent }) => {
  return (
    <div id="teleradiology" className="about-telerad">
      <Banner bannerHeader={aboutContent[0].content} />
      <Image source={scanImage1Right} alt="About us" />
      <Article
        className="back-green"
        title={aboutContent[1].header}
        text={
          <>
            <p>{aboutContent[1].content[0]}</p>
            <p>{aboutContent[1].content[1]}</p>
            <p className="bold">{aboutContent[1].content[2]}</p>
          </>
        }
      />
      <div className="accordion">
        <h2>{aboutContent[2].title}</h2>
        {aboutContent[2].content.map((element) => (
          <AccordionItem title={element.title} content={element.content} />
        ))}
      </div>
      <PromoBanner
        content={
          <>
            <h2>{aboutContent[3].header}</h2>
            {aboutContent[3].content.map((element, index) => (
              <p>{element}</p>
            ))}
          </>
        }
        overlayColor="#222a35e6"
        backImage={deskConsult2}
      />
    </div>
  );
};

export default AboutTelerad;
